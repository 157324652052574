<template>
  <div class="bg-white pb-20">
    <div class="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h1 class="text-2xl font-extrabold tracking-tight text-gray-700">{{loc.cart}}</h1>
      <div class="mt-12 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
        <section aria-labelledby="cart-heading" class="lg:col-span-7">

          <ul role="list" class="border-t border-b border-gray-200 divide-y divide-gray-200">
            <li v-for="(product, productIdx) in user.cart" :key="product.id" class="flex py-6 sm:py-10">
              <div class="flex-shrink-0">
                <img v-if="product.benefit.pictures.length" :src="product.benefit.pictures[0].image" class="w-10 h-10 rounded-md object-center object-cover sm:w-20 sm:h-20" />
                <div v-else class="bg-gray-300 w-10 h-10 rounded-md object-center object-cover sm:w-20 sm:h-20">

                </div>
              </div>

              <div class="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                <div class="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                  <div>
                    <div class="flex justify-between">
                      <h3 class="text-sm">
                        <a :href="product.href" class="font-medium text-gray-700 hover:text-gray-800">
                          {{ product.benefit.name }}
                        </a>
                      </h3>
                    </div>
                    <div class="mt-1 flex text-sm">
<!--                      <p class="text-gray-500">-->
<!--                        {{ product.benefit.color }}-->
<!--                      </p>-->
<!--                      <p v-if="product.size" class="ml-4 pl-4 border-l border-gray-200 text-gray-500">-->
<!--                        {{ product.size }}-->
<!--                      </p>-->
                    </div>
                    <p class="mt-1 text-sm font-medium text-gray-900">{{ monetary(product.benefit.price * (100 - product.benefit.discount) / 100, user.currency) }}</p>
                  </div>

                  <div class="mt-4 sm:mt-0 sm:pr-9">
                    <label :for="`quantity-${productIdx}`" class="sr-only">Quantity, {{ product.name }}</label>
                    <input v-model.lazy="product.count" v-debounce="400" @change="changed($event, product.id)" type="number" class="max-w-full rounded-md border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 sm:text-sm" />

                    <div class="absolute top-0 right-0">
                      <button type="button" class="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Remove</span>
                        <XIcon @click="toggle_cart(product.benefit.id)" class="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </section>

        <!-- Order summary -->
        <section aria-labelledby="summary-heading" class="mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5">
          <h2 id="summary-heading" class="text-lg font-medium text-gray-900">{{loc.order_summary}}</h2>

          <dl class="mt-6 space-y-4">
            <div class="border-t border-gray-200 pt-4 flex items-center justify-between">
              <dt class="flex items-center text-sm text-gray-600">
                <span>{{loc.tital_benefits}}</span>
                <a href="#" class="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">

<!--                  <QuestionMarkCircleIcon class="h-5 w-5" aria-hidden="true" />-->
                </a>
              </dt>
              <dd class="text-sm font-medium text-gray-900">{{user.cart.reduce((partialSum, a) => partialSum + a.count, 0)}}</dd>
            </div>
            <div class="border-t border-gray-200 pt-4 flex items-center justify-between">
              <dt class="flex text-sm text-gray-600">
                <span>{{loc.additional_payrole}}</span>
                <a href="#" class="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                  <div class="tooltip">
                    <QuestionMarkCircleIcon class="h-5 w-5"/>
                    <span class="tooltiptext">{{loc.additional_tax}}</span>
                  </div>
                </a>
              </dt>
              <dd class="text-sm font-medium text-gray-900">{{ monetary(0, user.currency) }}</dd>
            </div>
            <div class="border-t border-gray-200 pt-4 flex items-center justify-between">
              <dt class="text-base font-medium text-gray-900">{{loc.total}}</dt>
              <dd class="text-base font-medium text-gray-900">{{monetary(total, user.currency)}}</dd>
            </div>
          </dl>
          <div class="relative flex items-start pt-2">
            <div class="flex items-center h-5">
              <input v-model="understand" id="comments" aria-describedby="comments-description" name="comments" type="checkbox" class="focus:ring-base-500 h-4 w-4 text-base-600 border-gray-300 rounded" />
            </div>
            <div class="ml-3 text-sm">
              <p id="comments-description" class="text-gray-500">{{loc.checked_cart}}</p>
            </div>
          </div>

          <div class="mt-6">
            <button @click="complete_purchase" :disabled="disabled_button" :class="`w-full ${!disabled_button? 'bg-base-600 hover:bg-base-700 cursor-pointer' : 'bg-gray-500 cursor-not-allowed'} border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-base-500`">
              {{loc.proceed_to_checkout}}
            </button>
          </div>
          <p v-if="!user.cart_has_money" class="text-red-500">{{loc.by_error}}</p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XIcon } from '@heroicons/vue/solid'
import {mapGetters, mapActions, mapMutations} from "vuex";

export default {
  components: {
    XIcon,
    QuestionMarkCircleIcon
  },
  data() {
    return {
      understand: false,
      count_helper: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user'
    }),
    disabled_button() {
      return !this.understand || !this.user.cart_has_money || this.loading || this.total == 0
    },
    total() {
      return this.user.cart.reduce((partialSum, a) => partialSum + Number(a.benefit.price) * (100 - a.benefit.discount) / 100 * a.count, 0)
    }
  },
  methods: {
    ...mapActions('auth', {
      toggle_cart: 'toggleCart',
      fetchUser: 'fetchUser'
    }),
    ...mapMutations('auth', {
      resetCartItem: 'resetCartItem'
    }),
    async changed(e, id) {
      if(this.count_helper.findIndex(el => el[0] == e.target.value && el[1] == id) > -1) {
        this.loading = true
        this.count_helper = []
        let {data} = await this.axios.patch(`v2/cart-items/${id}/`, {count: e.target.value})
        await this.fetchUser()
        this.resetCartItem(data)
        this.loading = false
      } else {
        this.count_helper.push([e.target.value, id])
      }
    },
    async complete_purchase() {
      this.loading = true
      try {
        let res = await this.axios.post('v2/complete-purchase/')
        this.set_success(this.loc.transaction_successful)
        this.fetchUser()
        // this.$router.push('/')

      } catch (e) {
        console.log(e)
      }
      this.loading = false
    }
  }
}

</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.71);
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>